import React, {useEffect, useState} from 'react';
import Web3 from "web3";
import {socket} from "../../services/socket";
import axios from "../../utils/interceptors/";
import cn from 'classnames'

import IsShow from "../../store/IsShow";
import Button from "../../components/button";
import {observer} from "mobx-react-lite";
import UsersWallets from "../../store/UsersWallets";
import Select from "react-select";


import info from '../../assets/icons/wallets/info.png'
import useDebounce from "../../hooks/useDebounce";
import arrowPng from '../../assets/icons/arrow-down.png'
import metamaskPng from '../../assets/icons/wallets/meta-icon.png'

import "./style.scss";
import customStyles from "./customStyles";
import PopupNoWallet from "../../components/helpers/popupNoWallet/PopupNoWallet";
import Spinner from "../../components/helpers/spinner/Spinner";


const WithdrawPopup = observer(() => {

    const ethereum = window.ethereum
    const [lastWallet, setLastWallet] = useState(null)
    const [balance, setBalance] = useState(null)
    const [label, setLabel] = useState(null)
    const [walletId, setWalletId] = useState(null)
    const [amount, setAmount] = useState('')
    const [gas, setGas] = useState(0)
    const [withdrawGas, setWithdrawGas] = useState(0)
    const [gasUSD, setGasUSD] = useState(0)
    const [total, setTotal] = useState(0)
    const debouncedValue = useDebounce(amount, 1000)
    const [isWalletChecked, setWalletCheck] = useState(false)
    const [gryffinFee, setGryffinFee] = useState(0)

    const numberWithCommas = (num) => {
        if (num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    };

    const currentWalletList = () => {
        let currentWallet
        axios.get('/api/v1/get_last_wallet/')
            .then(res => {
                setLastWallet(res.data.wallet_address.toLowerCase())
                currentWallet = UsersWallets.List.find((el) => {
                    return el.address === res.data.wallet_address.toLowerCase()
                })
                if (currentWallet) {
                    if (isWalletChecked === false) {
                        setLabel(res.data.wallet_address.toLowerCase());
                        setWalletCheck(true)
                        setWalletId(res.data.wallet_address_id)
                        // the code below may come in handy in the future
                        // if (lastActiveAmount && amount === 0) {
                        //     setAmount(+lastActiveAmount);
                        // }
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const closeHandler = () => {
        IsShow.changeVisionWithdraw()
    }


    const inputValidation = (e) => {
        if (e.keyCode === 46 || e.keyCode === 8 || e.keyCode === 9 || e.ctrlKey || e.key === 'F12' || e.keyCode === 27 ||
            // Разрешаем: Ctrl+A
            (e.keyCode === 65 && e.ctrlKey === true) ||
            // Разрешаем: home, end, влево, вправо
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // Ничего не делаем
            return;
        } else {
            if (!e.target.value.includes('.') && e.key === '.') {
                return;
            }
            // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
            if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        }
    }

    const handleSelectChange = (e) => {
        setLabel(e.label)
        setWalletId(e.id)
        // socket.emit('get_actual_balances', {addresses: [e.label]})
        // socket.on('update_balance', (res) => {
        //     setBalance(res.balance)
        // })
    }

    const handleInputChange = (e) => {
        setAmount(e.target.value)
        if (ethereum && ethereum.selectedAddress) {
            socket.emit('get_actual_gas_price', {
                address_from: process.env.REACT_APP_GRYFFIN_SYSTEM_WALLET,
                address_to: ethereum.selectedAddress,
                amount: +e.target.value
            })
        } else {
            socket.emit('get_actual_gas_price', {
                address_from: process.env.REACT_APP_GRYFFIN_SYSTEM_WALLET,
                address_to: label,
                amount: +e.target.value
            })
        }
    }

    const calcTotal = () => {
        // 3 -- it's hardcoded Gryffin fee
        setTotal(+(Number(amount) + 3 + gasUSD).toFixed(5))
    }


    const calcGas = async (data) => {
        const web3 = new Web3(Web3.givenProvider || 'wss://rinkeby.infura.io/ws/v3/57360e5898c1436d9f2ab14ab5c85271')
        let price = 0
        await web3.eth.getGasPrice(function (e, r) {
        }).then(res => {
            price = res / 1000000000000000000;
            return price
        })
        await setGas(+(data.gas * price).toFixed(8)) // gas in ETH
        await setWithdrawGas(+data.gas)
        await setGasUSD(+(data.gas * price * 2069.52).toFixed(4)) // gas in USD
    }

    useEffect(() => {
        currentWalletList()
    })

    useEffect(() => {
        UsersWallets.fetchList()
        socket.on('update_gas', calcGas)
        calcTotal()
        return () => {
            socket.off('update_gas', calcGas)
        }
    }, [debouncedValue, gasUSD])

    const CustomArrow = () => {
        return (
            <img src={arrowPng} className={'arrow'} alt={'arrow'}/>
        )
    }

    const options = UsersWallets.List.map(wallet => {
        let obj = {}
        obj.value = wallet.balance
        obj.label = wallet.address
        obj.src = metamaskPng
        obj.id = wallet.id
        return obj
    })

    const FormatOptionLabel = ({value, label, src}) => {
        const optionStyles = {
            height: '30px',
            ':hover': {
                backgroundColor: 'grey',
            },
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
            borderRadius: '10px',
            padding: '5px',
            scrollbarColor: '#a879f6 #120c28',
        }
        return (
            <div>
                <div style={optionStyles}>
                    <img style={{marginRight: '6px'}} height={30} src={src || metamaskPng} alt={'wallet'}/>
                    <div>{label}</div>
                </div>
            </div>
        )
    }

    const [isOperation, setOperationStatus] = useState(true)

    // commented out so as not to break the current logic

    // const checkCurrentTerms = async () => {
    //
    //     // (await) will be get request for current fee and minimal amount
    //
    //
    //     if (gryffinFee !== gryffinFeeFromAdmin) {
    //         IsShow.changeVisionPopupCurrentFee()
    //     } else {
    //         sendRequest()
    //     }
    // }

    const sendRequest = () => {
        setOperationStatus(false)
        axios.post('/api/v1/withdraw/', {
            wallet_id: walletId,
            amount: amount,
            //hardcoded Gryffin fee = 3
            fee: total,
            gas: withdrawGas
        })
            .then((res) => {
                IsShow.changeVisionPopupWithdraw()
                setOperationStatus(true)
                setAmount('')
            })
            .catch(error => {
                console.log(error)
                IsShow.changeVisionWithdraw()
                setOperationStatus(true)
                setAmount('')
            })
    }

    axios.get('/api/v1/balance/').then(response => {
        setBalance(response.data.available)
    }).catch(e => {
        console.log(e)
    })

    const preSelectedLabel = () => {
        if (lastWallet) {
            const obj = {}
            let currentWallet = UsersWallets.List && UsersWallets.List.find((el) => {
                return el.address === lastWallet
            })

            if (currentWallet) {
                obj.label = lastWallet
            }

            return obj.label ? obj : null
        } else {
            return null
        }
    }


        return (
            <>
                <div
                    className={cn('overlay', {'active': IsShow.showWithdraw})}
                >
                    <div className='popup'>
                <span className='close'
                      onClick={closeHandler}>&times;
                </span>
                        <h2>Withdraw USDT</h2>
                        <div
                            className={cn('balance', {'error': amount > balance})}>Wallet
                            balance: {balance && numberWithCommas(balance)} USDT
                        </div>
                        <div className='selectTitle'>recipient wallet:</div>
                        {IsShow.isLoadingSelect ? <Spinner visible={IsShow.isLoadingSelect}/> :
                            <>
                                {preSelectedLabel() &&
                                    <Select
                                        components={{
                                            IndicatorsContainer: CustomArrow
                                        }}
                                        onChange={handleSelectChange}
                                        formatOptionLabel={FormatOptionLabel}
                                        styles={customStyles}
                                        defaultValue={preSelectedLabel()}
                                        placeholder={'Select wallet'}
                                        options={options}
                                    />}
                                {!preSelectedLabel() &&
                                    <Select
                                        components={{
                                            IndicatorsContainer: CustomArrow
                                        }}
                                        onChange={handleSelectChange}
                                        formatOptionLabel={FormatOptionLabel}
                                        styles={customStyles}
                                        placeholder={'Select wallet'}
                                        options={options}
                                    />}
                            </>}
                        <div className='inputName'>enter amount:</div>
                        <div className='inputWrapper'>
                            <input placeholder='0' value={amount} onChange={handleInputChange}
                                   onKeyDown={inputValidation}
                                   className={cn('inputWrapper__input', {'error': amount > balance || amount < 0.000001})}/>
                            <span className='inputWrapper__span'>USDT</span>
                        </div>
                        <div className={cn('alert', {'active-alert': amount <= 0.000001})}>
                            Please provide sum that is more than 0,000001 USDT
                        </div>
                        <div className={cn('alert', {'active-alert': total > balance})}>
                            The balance is not enough for transaction
                        </div>
                        <div className='gryff'>
                            Gryffin fee:
                            <span className='usdt'>3 USDT</span>
                            <span className='infoIcon'>
                        <img
                            src={info} alt={""}/>
                        <span className='tooltip'>
                        Gryffin fee is a sum required for the platform support
                    </span>
                    </span>
                        </div>
                        <div className='gas'>
                            Gas fee:
                            <span>{gas}ETH  [${gasUSD}]</span>
                            <span className='infoIcon'>
                        <img
                            src={info}
                            alt={""}/>
                    <span className='tooltip'>
                        Ethereum gas fee is needed to handle transaction within Ethereum network and pay miners. The provided value is the maximum sum that may be written off. If the gas fee is increased the transaction will not be handled and you will not lose more money on gas fee.
                    </span>
                    </span>
                        </div>
                        <div className='total'>
                            Total:
                            <span
                                className={cn('totalCount', {'error': total > balance})}>{total}</span>
                            USD
                            <span className='infoIcon'>
                        <img
                            src={info}
                            alt={""}/>
                    <span className='tooltip'>
                        The sum includes Gryffin commission, gas fee and desired sum for transaction. The value is <b>approximate</b> as it is based on the conversion rate. The gas fee will be withdrawn from your wallet in ETH
                    </span>
                    </span>
                        </div>
                        <div
                            className={cn('buttonsContainer', {'disabled': !isOperation || !amount || amount < 0.000001 || total > balance || label === null})}
                        >
                            <Button handlerFunction={sendRequest} name={'withdraw'}
                            />
                        </div>
                    </div>
                </div>
                <PopupNoWallet
                    message={'Please connect a wallet to withdraw your coins to it that helps you to protect losing your money by incorrect address providing'}/>
            </>
        );


});

export default WithdrawPopup;