import React from "react";
import {Link} from "react-router-dom";

import logo from "../../../src/assets/img/logo.png";

import "./style.scss";
import UsersWallets from "../../store/UsersWallets";

const SideBar = (props) => {
    UsersWallets.fetchList()
    return (
        <>
            <aside className="sidebar">
                <div className="sidebar-logo-wrap">
                    <Link to='/'>
                        <img src={logo} alt="Logo" className="sidebar-logo"/>
                    </Link>
                </div>

                <div className="sidebar-user-info"/>

                <nav className="sidebar-menu">

                    {/* For example */}
                    <ul>
                        <li>
                            <Link to="/wallets">
                                <button>Show wallet list</button>
                            </Link>
                        </li>
                        <li>
                            <Link to="/overview_balance">
                                <button>Overview balance</button>
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Link to="/users">Users</Link>*/}
                        {/*</li>*/}
                    </ul>

                </nav>
            </aside>
        </>
    )
};

SideBar.propTypes = {};

export default SideBar;