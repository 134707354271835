import React from 'react';
import styles from './PopupCurrentFee.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupCurrentFee = observer( () => {
    const closeHandler = () => {
        IsShow.changeVisionPopupCurrentFee()
    }

    return (
        <>
            <div className={cn(styles.overlay, !IsShow.showPopupCurrentFee && styles.hide)}>
                <div className={styles.popup}>
                   <div className={styles.contentWrapper}>
                       <div className={styles.subtitle}>
                           <div className={styles.sub1}>It seems that Gryffin fee size has been updated. Please review the provided data and confirm transaction </div>
                       </div>
                       <button onClick={closeHandler} className={styles.button}>Ok</button>
                   </div>
                </div>
            </div>
        </>

    );
});

export default PopupCurrentFee;