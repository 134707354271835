// TODO: Insert intl library and rewrite this function
export const replaceBalancesName = (name) => {
    switch (name) {
        case 'available':
            return 'Available in Wallet';

        case 'PL_realised':
            return  'P&L Realised';

        case 'PL_unrealised':
            return  'P&L Unrealised';

        case 'active_in_liquidity_pools':
            return  'Active in Liquidity Pools';

        case 'deposit_pending':
            return  'Deposit Pending';

        case 'withdrawn':
            return  'Withdrawn';

        case 'deposited':
            return  'Deposited';

        case 'funded_to_sustainable_project':
            return  'Funded to Sustainable Project';

        case 'withdrawal_pending':
            return  'Withdrawal Pending';

        default:
            return;
    }
}