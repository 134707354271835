import React, {useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";

import './styles.scss'

import UsersWallets from "../../store/UsersWallets";
import DisconnectButton from "../../components/helpers/disconnectButton/DisconnectButton";
import IsShow from "../../store/IsShow";
import PopupConfirmStore from "../../store/PopupConfirmStore";
import PopupConfirm from "../../components/helpers/popupConfirm/PopupConfirm";
import Pagination from "../../components/helpers/pagination/Pagination";
import metamask from '../../assets/icons/wallets/meta-icon.png'
import PopupEmptyList from "../../components/helpers/popupEmptyList/PopupEmptyList";
import {socket} from "../../services/socket";

const text1 = `Disconnecting this wallet means you will not be able to deposit or withdraw funds using this wallet address`;
const text2 = `Use other connected wallets for future transactions`;

const ListOfWallet = observer(() => {

    const ethereum = window.ethereum
    const [currentPage, setCurrentPage] = useState(1);
    let pageSize = 10;

    useEffect(() => {
        UsersWallets.fetchList()
    }, [])

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        if (UsersWallets.List.length !== 0) {
            return UsersWallets.List.slice(firstPageIndex, lastPageIndex);
        }
    }, [currentPage, UsersWallets.List]);

    const MapEmptyArray = ({arr}) => {
        return arr.map((el, i) => {
            return (
                <tr className='mock-item' key={i}>
                    <td className='mock-item__cell'>&nbsp;</td>
                    <td className='mock-item__cell'>&nbsp;</td>
                    <td className='mock-item__cell'>&nbsp;</td>
                    <td className='mock-item__cell'>&nbsp;</td>
                </tr>
            )
        })
    }

    const numberWithCommas = (num) => {
        if (num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    };

    const MapData = ({array}) => {

        const addressesFromList = () => {
            let addresses = []
            array.forEach(el => addresses.push(`${el.address}`))
            return addresses
        }

        const selectedAddressBalance = () => {
            let currentBalance
            if (ethereum) {
                array.forEach(el => {
                    if (el.address === ethereum.selectedAddress) {
                        currentBalance = el.balance
                    }
                })
            }
            return currentBalance
        }

        const [balance, setBalance] = useState(selectedAddressBalance)


        useEffect(() => {
            if (ethereum) {
                socket.emit('get_actual_balances', {addresses: addressesFromList()})
                socket.on('update_balance', (res) => {
                    UsersWallets.updateBalance(res)
                })
            }
        }, [UsersWallets.List])

        return array.map((wallet) =>
            <tr className='item' key={wallet.id}>
                <td className='item__name'>
                        <img className='item__name__icon' src={metamask} alt={''}/>
                    MetaMask
                </td>
                <td className='item__balance'>
                    ${numberWithCommas(wallet.balance) || 0}
                    <span className='item__balance__currency'>USDT</span>
                </td>
                <td className='item__address'>
                    {wallet.address}
                </td>
                <td className='item__button'>
                    <DisconnectButton
                        id={wallet.id}
                        name={"disconnect"}
                        title={"disconnect wallet ?"}
                        subtitle1={text1}
                        subtitle2={text2}
                    />
                </td>
            </tr>)
    }

    const GenerateRows = () => {
        let emptyArray;

        const fillEmptyArray = (currentLength) => {
            const fillCount = pageSize - currentLength;
            emptyArray = new Array(fillCount);
            emptyArray.fill('');
        };

        if (currentTableData) {
            fillEmptyArray(currentTableData.length);
        }
        if (currentTableData === undefined && UsersWallets.List.length === 0) {
            fillEmptyArray(0);
        }


        useEffect(() => {
            if (emptyArray.length === pageSize && currentTableData) {
                setCurrentPage(currentPage - 1)
            }
        }, [UsersWallets.List.length])

        return (
            <>
                {currentTableData ?
                    <>
                        <MapData array={currentTableData}/>
                        <MapEmptyArray key={1} arr={emptyArray}/>
                    </>
                    :
                    <MapEmptyArray key={2} arr={emptyArray}/>
                }
            </>
        )
    }

    return (
        <>
            <div className='tableBox'>
                <table style={{
                    display: IsShow.showWalletList ? 'table' : 'none'
                }}
                       className='table'>
                    <thead>
                    <tr className='table__tableBox'>
                        <th>Wallet</th>
                        <th>Balance</th>
                        <th>Wallet Key</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody className='table__tableBody'>
                    <GenerateRows/>
                    <tr className='mockRow'></tr>
                    </tbody>
                </table>
                {IsShow.showEmptyPopup && IsShow.showWalletList && <PopupEmptyList/>}
                {/*TODO: add reusable popup*/}
                {/*{<Popup*/}
                {/*    id={PopupConfirmStore.id}*/}
                {/*    title={PopupConfirmStore.title}*/}
                {/*    subtitle1={PopupConfirmStore.subtitle1}*/}
                {/*    subtitle2={PopupConfirmStore.subtitle2}*/}
                {/*    isDisconnect={true}*/}
                {/*/>}*/}
                {PopupConfirmStore.isShow &&
                    <PopupConfirm
                        id={PopupConfirmStore.id}
                        title={PopupConfirmStore.title}
                        subtitle1={PopupConfirmStore.subtitle1}
                        subtitle2={PopupConfirmStore.subtitle2}
                    />}
            </div>
            {IsShow.showWalletList && <Pagination
                currentPage={currentPage}
                totalCount={UsersWallets.List.length}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />}
        </>
    );
});

export default ListOfWallet;