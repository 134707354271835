import React from 'react';
import styles from './PopupPending.module.css'
import Spinner from "../spinner/Spinner";
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupPending = observer( () => {
    const closeHandler = () => {
        IsShow.changeVisionPendingPopup()
    }

    return (
        <>
            <div className={cn(styles.overlay, !IsShow.showPendingPopup && styles.hide)}>
                <div className={styles.popup}>
                  <div className={styles.contentWrapper}>
                      <h2>Waiting for action confirmation</h2>
                      <div className={styles.subtitle}>
                          <div className={styles.sub1}>Please complete operation in Metamask.</div>
                          {/*<div>Please complete previous operation and try again.</div>*/}
                      </div>
                      <button onClick={closeHandler} className={styles.button}>Ok</button>
                  </div>
                </div>
            </div>
        </>

    );
});

export default PopupPending;