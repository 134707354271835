import {makeAutoObservable} from "mobx";

class isShow {
    isVisible = false
    showPopupSpinner = true;
    showPopupConfirm = false;
    showWalletList = true;
    showEmptyPopup = false;
    showRejectPopup = false;
    showAlreadyConnectedPopup = false;
    showWrongChainPopup = false;
    showConnectWalletPopup = false;
    showPendingPopup = false;
    showReloadPopup = false;
    showReloadOnDeletePopup = false;
    showDeposit = false;
    showWithdraw = false;
    showPopupWithdraw = false;
    showPopupDepositNoWallet = false;
    showPopupIsMetamask = false;
    isLoading = false;
    isLoadingSelect = false;
    isLoadingBalances = false;
    showPopupCurrentFee = false;

    constructor() {
        makeAutoObservable(this)
    }

    setIsLoading(value) {
        this.isLoading = value;
    }

    setIsLoadingSelect(value) {
        this.isLoadingSelect = value;
    }

    setIsLoadingBalances(value) {
        this.isLoadingBalances = value;
    }

    changeVisability() {
        this.isVisible = !this.isVisible
    }

    changeVisionPopupSpinner() {
        this.showPopupSpinner = !this.showPopupSpinner
    }

    changeVisionPopupConfirm() {
        this.showPopupConfirm = !this.showPopupConfirm
    }

    changeVisionWalletList() {
        this.showWalletList = !this.showWalletList
    }

    changeVisionEmptyPopup() {
        this.showEmptyPopup = !this.showEmptyPopup
    }

    changeVisionRejectPopup() {
        this.showRejectPopup = !this.showRejectPopup
    }

    changeVisionAlreadyConnectedPopup() {
        this.showAlreadyConnectedPopup = !this.showAlreadyConnectedPopup
    }

    changeVisionWrongChainPopup() {
        this.showWrongChainPopup = !this.showWrongChainPopup
    }

    changeVisionConnectWalletPopup() {
        this.showConnectWalletPopup = !this.showConnectWalletPopup
    }

    changeVisionPendingPopup() {
        this.showPendingPopup = !this.showPendingPopup
    }

    changeVisionReloadPopup() {
        this.showReloadPopup = !this.showReloadPopup
    }

    changeVisionReloadOnDeletePopup() {
        this.showReloadOnDeletePopup = !this.showReloadOnDeletePopup
    }

    changeVisionDeposit() {
        this.showDeposit = !this.showDeposit
    }

    changeVisionWithdraw() {
        this.showWithdraw = !this.showWithdraw
    }

    changeVisionPopupWithdraw() {
        this.showPopupWithdraw = !this.showPopupWithdraw
    }

    changeVisionPopupDepositNoWallet() {
        this.showPopupDepositNoWallet = !this.showPopupDepositNoWallet
    }

    changeVisionPopupIsMetamask() {
        this.showPopupIsMetamask = !this.showPopupIsMetamask
    }

    changeVisionPopupCurrentFee() {
        this.showPopupCurrentFee = !this.showPopupCurrentFee
    }
}

export default new isShow()