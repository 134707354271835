import React, {useEffect, useRef, useState} from 'react';
import Web3 from "web3";
import {socket} from "../../services/socket";
import cn from 'classnames'

import IsShow from "../../store/IsShow";
import DepositButton from "../../components/button";
import {observer} from "mobx-react-lite";
import UsersWallets from "../../store/UsersWallets";
import Select from "react-select";


import info from '../../assets/icons/wallets/info.png'
import useDebounce from "../../hooks/useDebounce";
import arrowPng from '../../assets/icons/arrow-down.png'
import metamaskPng from '../../assets/icons/wallets/meta-icon.png'

import "./style.scss";
import customStyles from "./customStyles";
import json from "../../api/contract-ABI.json";
import PopupNoWallet from "../../components/helpers/popupNoWallet/PopupNoWallet";
import PopupIsMetamask from "../../components/helpers/popupIsMetamask/PopupIsMetamask";
import Spinner from "../../components/helpers/spinner/Spinner";
import axios from "../../utils/interceptors/index";


const DepositPopup = observer(() => {
    const ethereum = window.ethereum
    if (ethereum) {

        useEffect(() => {
            IsShow.setIsLoadingSelect(true)
            currentWalletList()
            socket.emit('get_actual_gas_price', {
                address_from: label || process.env.REACT_APP_GRYFFIN_SYSTEM_WALLET,
                address_to: process.env.REACT_APP_GRYFFIN_SYSTEM_WALLET,
                amount: 5 // predefined sum from admin in the future
            })
            socket.on('update_gas', calcGas)

        }, [])

        const [lastWallet, setLastWallet] = useState(null)
        const [balance, setBalance] = useState(null)
        const [label, setLabel] = useState(null)
        // amount is hardcoded, same as input (default)value (in the future it changes from admin panel)
        const [amount, setAmount] = useState(0)
        const [gas, setGas] = useState(0)
        const [gasUSD, setGasUSD] = useState(0)
        const [total, setTotal] = useState(0)
        const [isWalletChecked, setWalletCheck] = useState(false)
        const inputRef = useRef()
        const [isMatch, setMatch] = useState(false)
        const [isSelectChanged, setChange] = useState(false)
        const debouncedValue = useDebounce(amount, 1000)
        const [gryffinFee, setGryffinFee] = useState(0)
        const [minAmount, setMinAmount] = useState(0)

        const numberWithCommas = (num) => {
            if (num) {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        };


        const currentWalletList = () => {
            let currentWallet
            axios.get('/api/v1/get_last_wallet/')
                .then(res => {
                    IsShow.setIsLoadingSelect(false)
                    setLastWallet(res.data.wallet_address.toLowerCase())
                    currentWallet = UsersWallets.List.find((el) => {
                        return el.address === res.data.wallet_address.toLowerCase()
                    })
                    setMatch(res.data.wallet_address.toLowerCase() === ethereum.selectedAddress?.toLowerCase())
                    if (currentWallet) {
                        if (isWalletChecked === false) {
                            setBalance(currentWallet.balance);
                            setLabel(res.data.wallet_address.toLowerCase());
                            setWalletCheck(true)
                            setChange(true)
                            // the code below may come in handy in the future
                            // if (lastActiveAmount && amount === 0) {
                            //     setAmount(+lastActiveAmount);
                            // }
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    IsShow.setIsLoadingSelect(false)
                })
        }

        const closeHandler = () => {
            IsShow.changeVisionDeposit()
        }


        const symbolsCheck = (e) => {
            if (e.keyCode === 46 || e.keyCode === 8 || e.keyCode === 9 || e.ctrlKey || e.key === 'F12' || e.keyCode === 27 ||
                // Разрешаем: Ctrl+A
                (e.keyCode === 65 && e.ctrlKey === true) ||
                // Разрешаем: home, end, влево, вправо
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                // Ничего не делаем
                return;
            } else {
                if (!e.target.value.includes('.') && e.key === '.') {
                    return;
                }
                // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
                if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            }
        }

        const handleSelectChange = (e) => {
            setBalance(Number(e.value))
            setLabel(e.label)
            setChange(true)
            setMatch(e.label.toLowerCase() === ethereum.selectedAddress?.toLowerCase())
            socket.emit('get_actual_balances', {addresses: [e.label]})
            socket.on('update_balance', (res) => {
                setBalance(res.balance)
            })
        }

        const handleSetAmount = (e) => {
            setAmount(e.target.value)
            if (ethereum && ethereum.selectedAddress) {
                socket.emit('get_actual_gas_price', {
                    address_from: ethereum.selectedAddress,
                    address_to: label,
                    amount: Number(e.target.value)
                })
            } else {
                socket.emit('get_actual_gas_price', {address: label, amount: Number(e.target.value)})
            }
        }

        const calcTotal = () => {
            // 3 -- it's hardcoded Gryffin fee
            setTotal(+(Number(amount) + gryffinFee + gasUSD).toFixed(4))
        }

        const web3 = new Web3(Web3.givenProvider)

        const calcGas = async (data) => {
            let price = 0
            await web3.eth.getGasPrice(function (e, r) {
            }).then(res => {
                price = res / 1000000000000000000;
                return price
            })
            await setGas(+(data.gas * price).toFixed(8))
            await setGasUSD(+(data.gas * price * 2069.52).toFixed(4))
        }


        useEffect(() => {
            socket.on('update_gas', calcGas)
            calcTotal()
            return () => {
                socket.off('update_gas', calcGas)

            }
        }, [debouncedValue])


        const CustomArrow = () => {
            return (
                <img src={arrowPng} className={'arrow'} alt={'arrow'}/>
            )
        }

        const options = UsersWallets.List.map(wallet => {
            let obj = {}
            obj.value = wallet.balance
            obj.label = wallet.address
            obj.src = metamaskPng
            return obj
        })

        const FormatOptionLabel = ({value, label, src}) => {
            const optionStyles = {
                height: '30px',
                ':hover': {
                    backgroundColor: 'grey',
                },
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
                borderRadius: '10px',
                padding: '5px',
                scrollbarColor: '#a879f6 #120c28',
            }
            return (
                <div>
                    <div style={optionStyles}>
                        <img style={{marginRight: '6px'}} height={30} width={30} src={src || metamaskPng}
                             alt={'wallet'}/>
                        <option value={value}>{label}</option>
                    </div>
                </div>
            )
        }


        const isSuitableNetwork = (usersChain) => {
            const availableNetworks = process.env.REACT_APP_SUPPORTABLE_NETWORK_IDS.split(',')
            return availableNetworks.includes(usersChain && usersChain.toString())
        }

        const [network, setNetwork] = useState(false);

        ethereum.on('chainChanged', (chainId) => {
            const availableNetworks = process.env.REACT_APP_SUPPORTABLE_NETWORK_IDS.split(',')
            const match = availableNetworks.includes(chainId.split('')[2] && chainId.split('')[2].toString())
            match ? setNetwork(true) : setNetwork(false)
        });

        ethereum.on('accountsChanged', (account) => {
            setMatch(!!(label !== null && account[0] === label))
        })

        const contractWeb3 = new Web3('wss://rinkeby.infura.io/ws/v3/32012e5f91af42deba2118de1597d38e')
        const contract = new contractWeb3.eth.Contract(json, '0xd92e713d051c37ebb2561803a3b5fbabc4962431')

        const [isOperation, setOperationStatus] = useState(true)

        // commented out so as not to break the current logic

        // const checkCurrentTerms = async () => {
        //
        //     // (await) will be get request for current fee and minimal amount
        //
        //
        //     if ((minAmount !== minAmountFromAdmin || gryffinFee !== gryffinFeeFromAdmin)) {
        //         IsShow.changeVisionPopupCurrentFee()
        //     } else {
        //         sendRequest()
        //     }
        // }

        const sendRequest = async () => {
            const data = contract.methods.transfer(process.env.REACT_APP_GRYFFIN_SYSTEM_WALLET, Math.round((total * 1000000))).encodeABI()

            const transactionParameters = {
                to: '0xd92e713d051c37ebb2561803a3b5fbabc4962431',
                from: ethereum.selectedAddress,
                data,
            };
            setOperationStatus(false)
            return await ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            }).then(() => {
                IsShow.changeVisionDeposit()
                setOperationStatus(true)
            })
                .catch((error) => {
                    console.log(error)
                    IsShow.changeVisionDeposit()
                    setOperationStatus(true)
                });
        }

        useEffect(() => {
            // need to find optimize solution (lot of rerenders)
            setNetwork(isSuitableNetwork(ethereum.networkVersion))
            calcTotal()
        })

        const preSelectedLabel = () => {
            if (lastWallet) {
                const obj = {}
                let currentWallet = UsersWallets.List && UsersWallets.List.find((el) => {
                    return el.address === lastWallet
                })

                if (currentWallet) {
                    obj.label = lastWallet
                }
                return obj.label ? obj : null
            } else {
                return null
            }
        }


        return (
            <>
                <div
                    className={cn('overlay', {'active': IsShow.showDeposit})}
                >
                    <div className='popup'>
                        <span className='close' onClick={closeHandler}>&times;</span>
                        <h2>Deposit USDT</h2>
                        <div
                            className={cn('balance', {'error': amount > balance})}>Wallet
                            balance: {balance && numberWithCommas(balance)} USDT
                        </div>
                        <div className='selectTitle'>select a wallet</div>
                        {/*temporary solution*/}
                        {IsShow.isLoadingSelect || !preSelectedLabel() ? <Spinner visible={true}/> :
                            <>
                                {preSelectedLabel() &&
                                    <Select
                                        components={{
                                            IndicatorsContainer: CustomArrow
                                        }}
                                        onChange={handleSelectChange}
                                        formatOptionLabel={FormatOptionLabel}
                                        styles={customStyles}
                                        defaultValue={preSelectedLabel()}
                                        placeholder={'Select wallet'}
                                        options={options}
                                    />}
                                {!preSelectedLabel() &&
                                    <Select
                                        components={{
                                            IndicatorsContainer: CustomArrow
                                        }}
                                        onChange={handleSelectChange}
                                        formatOptionLabel={FormatOptionLabel}
                                        styles={customStyles}
                                        placeholder={'Select wallet'}
                                        options={options}
                                    />}
                            </>}
                        <div
                            className={cn('alert', {'active-alert': !isMatch && isSelectChanged || (!ethereum.selectedAddress && label)})}>
                            Please switch to the selected address {label} or verify connection in your Metamask account
                        </div>
                        <div className={cn('alert', {'active-alert': !network})}>
                            Please review the selection of "Rinkeby" in the selected wallet
                        </div>
                        <div className='inputName'>enter amount:</div>
                        <div className='inputWrapper'>
                            <input defaultValue={minAmount} placeholder='0' ref={inputRef} onChange={handleSetAmount}
                                   onKeyDown={symbolsCheck}
                                   className={cn('inputWrapper__input', {'error': amount < minAmount || amount > balance})}/>
                            <span className='inputWrapper__span'>USDT</span>
                        </div>
                        <div className={cn('alert', {'active-alert': amount > balance})}>
                            The balance is not enough for transaction
                        </div>
                        <div className='min'>Minimum deposit amount is {minAmount} USDT</div>
                        <div className='gryff'>
                            Gryffin fee:
                            <span className='usdt'>{gryffinFee} USDT</span>
                            <span className='infoIcon'>
                        <img
                            src={info} alt={""}/>
                        <span className='tooltip'>
                            Gryffin fee is a sum required for the platform support
                        </span>
                        </span>
                        </div>
                        <div className='gas'>
                            Gas fee:
                            <span>{gas}ETH  [${gasUSD}]</span>
                            <span className='infoIcon'>
                        <img
                            src={info}
                            alt={""}/>
                    <span className='tooltip'>
                        Ethereum gas fee is needed to handle transaction within Ethereum network and pay miners. The provided value is the maximum sum that may be written off. If the gas fee is increased the transaction will not be handled and you will not lose more money on gas fee.
                    </span>
                    </span>
                        </div>
                        <div className='total'>
                            Total:
                            <span
                                className={cn('totalCount', {'error': total > balance})}>{total}</span>
                            USD
                            <span className='infoIcon'>
                        <img
                            src={info}
                            alt={""}/>
                    <span className='tooltip'>
                        The sum includes Gryffin commission, gas fee and desired sum for transaction. The value is <b>approximate</b> as it is based on the conversion rate. The gas fee will be withdrawn from your wallet in ETH
                    </span>
                    </span>
                        </div>
                        <div
                            className={cn('buttonsContainer', {'disabled': !isOperation || amount < 5 || total > balance || !isMatch || !network})}
                        >
                            <DepositButton handlerFunction={sendRequest} name={'deposit'}
                            />
                        </div>
                    </div>
                </div>
                <PopupNoWallet
                    message={'You do not have connected wallet. Please connect at least one wallet, it will help to avoid mistakes during the address providing'}/>
            </>
        );


    } else {
        return (
            <PopupIsMetamask/>
        )
    }
});

export default DepositPopup;