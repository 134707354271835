import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

import ListOfWallet from "../listOfWallet";
import Home from "../home";
import DepositPopup from "../deposit";
import WithdrawPopup from "../withdraw";
import Overview from "../overviewBalance";


const RoutesWrapper = (props) => (
    <Switch>
        <Route exact path="/">
            <Home />
        </Route>

        <Route exact path="/wallets">
            <ListOfWallet />
        </Route>

        <Route exact path="/overview_balance">
            <Overview />
        </Route>

        {/*<Route path="*">*/}
        {/*  <NotFoundPage />*/}
        {/*</Route>*/}
    </Switch>
);

export default RoutesWrapper;
