import React from 'react';
import styles from './PopupReject.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupReject = observer( () => {
    const closeHandler = () => {
        IsShow.changeVisionRejectPopup()
    }

    return (
        <>
            <div className={cn(styles.overlay, !IsShow.showRejectPopup && styles.hide)}>
                <div className={styles.popup}>
                   <div className={styles.contentWrapper}>
                       <h2>Failed Connection</h2>
                       <div className={styles.subtitle}>
                           <div className={styles.sub1}>The connection was rejected via the wallet, please try again or use different wallet</div>
                       </div>
                       <button onClick={closeHandler} className={styles.button}>Ok</button>
                   </div>
                </div>
            </div>
        </>

    );
});

export default PopupReject;