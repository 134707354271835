import React from 'react';
import usePagination, { DOTS } from "../../../hooks/usePagination";
import classnames from "classnames";
import styles from './Pagination.module.css'

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;


    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0) {
        return null;
    }

    const onNext = () => {
        if (currentPage <= 1) {
            onPageChange(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    let lastPage = paginationRange[paginationRange.length - 1];


    return (
        <div>
            <ul
                className={classnames(styles.paginationContainer)}
            >
                <li
                    className={classnames(styles.paginationItem, styles.prev, currentPage === 1 && styles.disabled)}
                    onClick={onPrevious}
                >
                    <div className={styles.left}>Previous</div>
                </li>
                {paginationRange.map(pageNumber => {

                    if (pageNumber === DOTS) {
                        return <li className={styles.dots}>&#8230;</li>;
                    }
                    return (
                        <li key={Number(pageNumber)}
                            className={classnames(styles.paginationItem, pageNumber === currentPage && styles.selected)}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </li>
                    );
                })}
                <li
                    className={classnames(styles.paginationItem, styles.next, currentPage === lastPage && styles.disabled)}
                    onClick={onNext}
                >
                    <div className={styles.right}>Next</div>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;