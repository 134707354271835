import React from 'react';
import styles from "./DisconnectButton.module.css";
import IsShow from "../../../store/IsShow";
import PopupConfirm from "../../../store/PopupConfirmStore";

const DisconnectButton = (props) => {
    const popupCloseHandler = () => {
        IsShow.changeVisionPopupConfirm()
        PopupConfirm.pushData(props, true)
    };
    return (
        <div>
            <button className={styles.button} onClick={popupCloseHandler}>{props.name}</button>
        </div>

    );
};

export default DisconnectButton;