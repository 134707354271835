import {makeAutoObservable} from "mobx";

class PopupConfirmStore {
    isShow = false
    id = null
    title = 'disconnect wallet ?'
    name = 'disconnect'
    subtitle1 = 'Disconnecting this wallet means you will not be able to deposit or withdraw funds using this wallet address'
    subtitle2 = 'Use other connected wallets for future transactions'

    constructor() {
        makeAutoObservable(this)
    }

    pushData(data, isShow) {
        this.isShow = isShow
        this.id = data.id
        this.title = data.title
        this.name = data.name
        this.subtitle1 = data.subtitle1
        this.subtitle2 = data.subtitle2
    }

}

export default new PopupConfirmStore()