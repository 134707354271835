import React from 'react';
import styles from './PopupAlreadyConnected.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupAlreadyConnected = observer( () => {
    const closeHandler = () => {
        IsShow.changeVisionAlreadyConnectedPopup()
    }

    return (
        <>
            <div className={cn(styles.overlay, !IsShow.showAlreadyConnectedPopup && styles.hide)}>
                <div className={styles.popup}>
                    <div className={styles.contentWrapper}>
                        <h2>Action Required</h2>
                        <div className={styles.subtitle}>
                            <div className={styles.sub1}>
                                It seems that you've already had connected addresses.
                                <ul>
                                    <h3>To connect a new Metamask wallet please follow the instruction:</h3>
                                    <li>1. Open Metamask</li>
                                    <li>2. Select account</li>
                                    <li>3. Trigger connection via the Metamask</li>
                                    <li>4. Initiate connection via the Gryffin once more</li>
                                </ul>
                            </div>
                        </div>
                        <button onClick={closeHandler} className={styles.button}>Ok</button>
                    </div>
                </div>
            </div>
        </>

    );
});

export default PopupAlreadyConnected;