import axios from "axios";

import { URLS } from "../../api/consts";
import IsShow from "../../store/IsShow";
import {runInAction} from "mobx";

const getToken = () => {
    const token = localStorage.getItem("authToken");
    return JSON.parse(token) || "";
};

axios.defaults.baseURL = URLS.baseURL;
axios.defaults.headers.common["Authorization"] =
    getToken() || process.env.REACT_APP_TOKEN;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["accept"] = "application/json";
axios.defaults.timeout = 30000;

const requestInterceptSuccess = (config) => {
    if (config.headers.isLoader) {
        IsShow.changeVisability()
        runInAction(() => {
            IsShow.showPopupSpinner = true
        })
        return config
    }
    return config;
};

const requestInterceptError = (error) => {
    console.warn(error);
    return Promise.reject(error);
};

axios.interceptors.request.use(requestInterceptSuccess, requestInterceptError);

const responseInterceptSuccess = (response) => {
    return response;
};

const responseInterceptError = (error) => {
    if (error && error.response) {
        return Promise.reject(error.response);
    }
    return Promise.reject(error);
};

axios.interceptors.response.use(
    responseInterceptSuccess,
    responseInterceptError
);

export default axios
