import React from 'react';
import styles from './PopupReload.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupReload = observer( () => {
    const closeHandler = () => {
        IsShow.changeVisionReloadPopup()
    }

    return (
        <>
            <div className={cn(styles.overlay, !IsShow.showReloadPopup && styles.hide)}>
                <div className={styles.popup}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.subtitle}>
                            <div className={styles.sub1}>
                                It seems that you don't have Metamask extension in the browser, so we've added instruction for wallet getting.
                                <p>Please, re-load the page after setting up Metamask extension, so that we will be able to allow you to conduct connection.</p>
                            </div>
                        </div>
                        <button onClick={closeHandler} className={styles.button}>Ok</button>
                    </div>
                </div>
            </div>
        </>

    );
});

export default PopupReload;