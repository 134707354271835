import React from 'react';
import styles from './PopupSpinner.module.css'
import Spinner from "../spinner/Spinner";
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'

const PopupSpinner = observer( (props) => {
    const closeHandler = () => {
        IsShow.changeVisability()
    }

    return (
        <>
            <div className={cn(styles.overlay, !IsShow.isVisible && styles.hide)}>
                <div className={styles.popup}>
                  <div className={styles.contentWrapper}>
                      <h2>{props.title}</h2>
                      <div className={styles.subtitle}>
                          <div className={styles.sub1}>{props.subtitle1}</div>
                          <div>{props.subtitle2}</div>
                      </div>
                      <button onClick={closeHandler} style={{
                          display: props.spinner ? 'none' : 'block'
                      }} className={styles.button}>Ok</button>
                      <Spinner visible={props.spinner}/>
                  </div>
                </div>
            </div>
        </>

    );
});

export default PopupSpinner;