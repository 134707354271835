import React from "react";

import SideBar from "components/sidebar";
import Header from "components/header";
import RoutesWrapper from "containers/routes";

import "./style.scss";
import ContentHeader from "../../components/content/contentHeader";

const App = () => (
    <div className="wrapper">
        <div className="sidebar-wrap">
            <SideBar/>
        </div>
        <Header/>
        <div className="routes-wrap">
            <ContentHeader/>
            <RoutesWrapper/>
        </div>
    </div>
);


export default App;
