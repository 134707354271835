import React from 'react';
import styles from './PopupWrongChain.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupWrongChain = observer( () => {
    const closeHandler = () => {
        IsShow.changeVisionWrongChainPopup()
    }

    return (
        <>
            <div className={cn(styles.overlay, !IsShow.showWrongChainPopup && styles.hide)}>
                <div className={styles.popup}>
                    <div className={styles.contentWrapper}>
                        <h2>Failed Connection</h2>
                        <div className={styles.subtitle}>
                            <div className={styles.sub1}>Please review the selection of "Ethereum Mainnet", "Rinkeby" in the selected wallet.</div>
                        </div>
                        <button onClick={closeHandler} className={styles.button}>Ok</button>
                    </div>
                </div>
            </div>
        </>

    );
});

export default PopupWrongChain;