import React from 'react';
import styles from './PopupWithdraw.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupWithdraw = observer(() => {
    const closeHandler = () => {
        IsShow.changeVisionPopupWithdraw()
        IsShow.changeVisionWithdraw()
    }

    return (
        <div className={cn(styles.overlay, !IsShow.showPopupWithdraw && styles.hide)}>
            <div className={styles.popup}>
                <div className={styles.contentWrapper}>
                    <h2>Created Transaction</h2>
                    <div className={styles.subtitle}>
                        <div className={styles.sub1}>Transaction was created and waiting for confirmation, you may
                            verify its status in the transaction list
                        </div>
                    </div>
                    <button onClick={closeHandler} className={styles.button}>Ok</button>
                </div>
            </div>
        </div>

    );
});

export default PopupWithdraw;