import React from 'react';
import styles from './PopupReload.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupReloadOnDelete = observer( () => {
    const closeHandler = () => {
        IsShow.changeVisionReloadOnDeletePopup()
    }

    return (
        <>
            <div className={cn(styles.overlay, !IsShow.showReloadOnDeletePopup && styles.hide)}>
                <div className={styles.popup}>
                    <div className={styles.contentWrapper}>
                        <h1>Action required</h1>
                        <div className={styles.subtitle}>
                            <div className={styles.sub1}>
                                It seems there is no connection to Metamask. Please reload the page and try to connect wallet once more.
                            </div>
                        </div>
                        <button onClick={closeHandler} className={styles.button}>Ok</button>
                    </div>
                </div>
            </div>
        </>

    );
});

export default PopupReloadOnDelete;