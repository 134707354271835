import React from 'react';
import styles from './PopupIsMetamask.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupIsMetamask = observer(() => {
    const closeHandler = () => {
        IsShow.changeVisionPopupIsMetamask()
    }

    return (
        <div className={cn(styles.overlay, !IsShow.showPopupIsMetamask && styles.hide)}>
            <div className={styles.popup}>
                <div className={styles.contentWrapper}>
                    <div className={styles.subtitle}>
                        <div className={styles.sub1}>
                            It seems your browser doesn't have Metamask extension, please follow <a href='https://metamask.io/faqs/' target='_blank'>the instructions</a> to set up it.
                            If you've already downloaded it, so try to re-load the page and we will verify its availability
                        </div>
                    </div>
                    <button onClick={closeHandler} className={styles.button}>Ok</button>
                </div>
            </div>
        </div>

    );
});

export default PopupIsMetamask;