import React from "react";

const Home = () => {

    return (
        <main></main>
    );
};


export default Home;
