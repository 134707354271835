import React from 'react';


import './style.scss'

const Button = ({ handlerFunction, name }) => {
    return (
        <button className='button' onClick={handlerFunction}>{name}</button>
    );
};

export default Button;