import React from 'react';
import styles from './PopupNoWallet.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import cn from 'classnames'


const PopupNoWallet = observer(( { message }) => {
    const closeHandler = () => {
        IsShow.changeVisionPopupDepositNoWallet()
    }

    return (
        <div className={cn(styles.overlay, !IsShow.showPopupDepositNoWallet && styles.hide)}>
            <div className={styles.popup}>
                <div className={styles.contentWrapper}>
                    <div className={styles.subtitle}>
                        <div className={styles.sub1}>
                            {message}
                        </div>
                    </div>
                    <button onClick={closeHandler} className={styles.button}>Ok</button>
                </div>
            </div>
        </div>

    );
});

export default PopupNoWallet;