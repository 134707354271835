import React from 'react';
import cn from "classnames";

import IsShow from "../../../store/IsShow";
import styles from "../popupSpinner/PopupSpinner.module.css";

const PopupEmptyList = () => {
    const closeHandler = () => {
        IsShow.changeVisionEmptyPopup()
    }

    return (

        <div className={cn(styles.overlay, !IsShow.showEmptyPopup && styles.hide)}>
            <div className={styles.popup}>
               <div className={styles.contentWrapper}>
                   <h2>wallet not connected</h2>
                   <div className={styles.subtitle}>
                       <div className={styles.sub1}>It appears you don't currently have any wallets connected.</div>
                       <div>Connect a wallet to deposit or withdraw funds.</div>
                   </div>
                   <button onClick={closeHandler}
                           className={styles.button}>continue
                   </button>
               </div>
            </div>
        </div>


    );
};

export default PopupEmptyList;