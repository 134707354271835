const customStyles = {

    control: () => ({
        backgroundColor: 'black',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px',
        borderRadius: '10px',
        borderStyle: 'solid',
        borderColor: '#a879f6',
        minHeight: '40px',
        fontSize: '12px',
        color: 'white',
        padding: '5px',
    }),

    container: () => ({
        width: '370px',
        marginTop: '10px',
        marginBottom: '10px',
        position: 'relative',
        display: 'inline-block'
    }),

    valueContainer: () => ({
        position: 'relative',
        display: 'inline-block',
        color: 'white'
    }),

    singleValue: () => ({

    }),

    placeholder: () => ({
        marginLeft: '2px',
        marginRight: '2px',
        fontSize: '16px'
    }),

    input: () => ({
        opacity: 0,
        position: 'absolute',
        height: '100%',
        width: '100%'
    }),

    menu: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        backgroundColor: '#fff',
        scrollBehavior: 'smooth',
    }),

    menuList: () => ({
        // none of react-select's styles are passed to <Control />
        margin: 0,
        marginTop: '10px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollBehavior: 'auto',
        position: 'absolute',
        backgroundColor: 'black',
        color: 'white',
        zIndex: 100,
        width: '100%',
        height: '200px',
        border: '1px',
        borderRadius: '10px',
        borderStyle: 'solid',
        borderColor: '#a879f6',
        padding: '5px 0',
        boxSizing: 'border-box',
        scrollbarWidth: 'thin',
        scrollbarColor: '#a879f6 #120c28'
    }),

    option: () => ({
        height: '30px',
        ':hover': {
            backgroundColor: 'grey',
        },
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        borderRadius: '10px',
        padding: '5px',
        scrollbarColor: '#a879f6 #120c28'
    })
};

export default customStyles;