import React from 'react';
import {observer} from "mobx-react-lite";

import './styles.scss'

import PopupWallets from "../../helpers/popupWallets/PopupWallets";
import PopupSpinner from "../../helpers/popupSpinner/PopupSpinner";
import IsShow from "../../../store/IsShow";
import PopupReject from "../../helpers/popupReject/PopupReject";
import PopupAlreadyConnected from "../../helpers/popupAlreadyConnected/PopupAlreadyConnected";
import PopupWrongChain from "../../helpers/popupWrongChain/PopupWrongChain";
import PopupPending from "../../helpers/popupPending/PopupPending";
import PopupReload from "../../helpers/popupReload/PopupReload";
import PopupReloadOnDelete from "../../helpers/popupReloadOnDelete/PopupReloadOnDelete";
import PopupWithdraw from "../../helpers/popupWithdraw/PopupWithdraw";
import PopupNoWallet from "../../helpers/popupNoWallet/PopupNoWallet";
import PopupCurrentFee from "../../helpers/popupCurrentFee/PopupCurrentFee";

const completeTitle = 'Successful Connection';
const completeSubtitle = 'The connection was confirmed, you may use it for coins inputting or withdrawal';

const ContentHeader = observer( () => {
    const popupCloseHandler = () => {
        IsShow.changeVisionConnectWalletPopup()
    };


    return (
        <div className="section">
            <h1 className="section__pageTitle">home</h1>
            <button onClick={popupCloseHandler} className="section__pageTitle__button">connect wallet
            </button>
            <PopupReload />
            <PopupReloadOnDelete />
            <PopupWallets />
            <PopupAlreadyConnected />
            <PopupReject />
            <PopupWrongChain />
            <PopupPending />
            <PopupWithdraw />
            <PopupCurrentFee />

            {<PopupSpinner
                title={ !IsShow.showPopupSpinner ?  completeTitle : null}
                subtitle1={ !IsShow.showPopupSpinner ? completeSubtitle: null}
                spinner={ IsShow.showPopupSpinner}
            />}
        </div>
    );
});

export default ContentHeader;