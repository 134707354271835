import React, {useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";

import './styles.scss'

import UsersWallets from "../../store/UsersWallets";
import axios from "../../utils/interceptors/";
import IsShow from "../../store/IsShow";
import DepositPopup from "../deposit";
import WithdrawPopup from "../withdraw";
import {replaceBalancesName} from "../../utils/replaceBalancesName";
import Spinner from "../../components/helpers/spinner/Spinner";


const Overview = observer(() => {
    const ethereum = window.ethereum

    const pageSize = 10;

    const [data, setData] = useState(null)

    useEffect(() => {
        UsersWallets.fetchList()
        getBalance()
        IsShow.setIsLoading(true)
        IsShow.setIsLoadingBalances(true)
        IsShow.setIsLoadingSelect(true)
    }, [])

    const getBalance = () => {
        axios.get('/api/v1/balance/')
            .then(res => {
                setData(res.data)
                IsShow.setIsLoadingBalances(false)
            })
            .catch(error => {
                console.log(error)
                IsShow.setIsLoadingBalances(false)
            })
    }

    const MapEmptyArray = ({arr}) => {
        return arr.map((el, i) => {
            return (
                <tr className='mock-item' key={i}>
                    <td className='mock-item__cell'>&nbsp;</td>
                    <td className='mock-item__cell'>&nbsp;</td>
                    <td className='mock-item__cell'>&nbsp;</td>
                    <td className='mock-item__cell'>&nbsp;</td>
                </tr>
            )
        })
    }


    const MapData = ({data}) => {
        return Object.entries(data).map(([name, value]) => {
            return (
                <tr className='item' key={name}>
                    <td className='item__name'>{replaceBalancesName(name)}</td>
                    <td className='item__value'>
                        ${value.toLocaleString()}
                        <span className='item__balance__currency'>USDT</span>
                    </td>
                </tr>
            )
        })
    }

    const GenerateRows = () => {
        let emptyArray;

        const fillEmptyArray = (currentLength) => {
            const fillCount = pageSize - currentLength;
            emptyArray = new Array(fillCount);
            emptyArray.fill('');
        };

        fillEmptyArray(9);

        return (
            <>
                {data ?
                    <>
                        <MapData data={data}/>
                        <MapEmptyArray key={1} arr={emptyArray}/>
                    </>
                    :
                    <MapEmptyArray key={2} arr={emptyArray}/>
                }
            </>
        )
    }

    const handleClickDeposit = () => {
        if (ethereum) {
            IsShow.changeVisionDeposit()
            if (!UsersWallets.List.length) {
                IsShow.changeVisionDeposit()
                IsShow.changeVisionPopupDepositNoWallet()
            }
        } else {
            IsShow.changeVisionPopupIsMetamask()
        }
    }

    const handleClickWithdraw = () => {
        if (UsersWallets.List.length) {
            IsShow.changeVisionWithdraw()
        } else {
            IsShow.changeVisionPopupDepositNoWallet()
        }
    }

    return (
        <>
            {IsShow.isLoadingBalances || IsShow.isLoading ?
                <Spinner isOverlay={true} isAbsolute={true} visible={true}/> :
                <>
                    <div className='tableBox'>
                        <table className='table'>
                            <thead>
                            <tr className='table__tableBox'>
                                <th>Details</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody className='table__tableBody'>
                            <GenerateRows/>
                            <tr className='mockRow'>
                                <td className='mockRow__buttons-wrapper'>
                                    <button className='mockRow__buttons-wrapper__button' onClick={handleClickDeposit}>Deposit USDT</button>
                                    <button className='mockRow__buttons-wrapper__button' onClick={handleClickWithdraw}>Withdraw USDT</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <DepositPopup/>
                    <WithdrawPopup/>
                </>
            }
        </>
    );
});

export default Overview;