import styles from './PopupConfirm.module.css'
import IsShow from "../../../store/IsShow";
import {observer} from "mobx-react-lite";
import UsersWallets from "../../../store/UsersWallets";
import cn from "classnames";

const PopupConfirm = observer( (props) => {
    const closeHandler = () => {
        IsShow.changeVisionPopupConfirm()
    };


    const closeAndDisconnect = () => {
        UsersWallets.disconnectWallet(props.id)
        IsShow.changeVisionPopupConfirm()
    }

    return (
        <>
            <div
                className={cn(styles.overlay, IsShow.showPopupConfirm && styles.hide)}
            >
                <div className={styles.popup}>
                    <div className={styles.contentWrapper}>
                        <h2>{props.title}</h2>
                        <div className={styles.subtitle}>
                            <div className={styles.sub1}>{props.subtitle1}<p>{props.subtitle2}</p></div>
                        </div>
                        <div className={styles.buttonsContainer}>
                            <button onClick={closeHandler} className={styles.buttonCancel}>Cancel</button>
                            <button onClick={closeAndDisconnect} className={styles.buttonAccept}>Disconnect</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default PopupConfirm;