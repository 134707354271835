import React from 'react';
import './Spinner.css'
import cn from "classnames";

const Spinner = ( { visible, isOverlay, isAbsolute } ) => {
    return (
        <div className={cn({'overlaySpinner': isOverlay && visible})}>
            <div className={cn('loader', {'visible': visible, 'absolute': isAbsolute})}></div>
        </div>
    );
};

export default Spinner;