import React from "react";

import notice from '../../assets/icons/notice-icon.png';
import messages from '../../assets/icons/messages-icon.png';
import gifts from '../../assets/icons/gifts-icon.png';
import settings from '../../assets/icons/settings-icon.png';
import search from '../../assets/icons/search-icon.png';

import './styles.scss';

const Header = (props) => (
    <header className='header'>
        <div className='header-search'>
            <input
                type='text'
                className='header-search__input'
                placeholder='Search...'
            />
            <img src={search} className='header-search__icon' alt={"search"}/>
        </div>

        <ul className='header-icons'>
            <li className='header-icons__item'>
                 <img src={notice} className='header-icons__icon' alt='notice' />
                {/*<span className='header-icons__badge'>1</span>*/}
            </li>
            <li className='header-icons__item'>
                 <img src={messages} className='header-icons__icon' alt='messages' />
                {/*<span className='header-icons__badge'>1</span>*/}
            </li>
            <li className='header-icons__item'>
                 <img src={gifts} className='header-icons__icon' alt='gifts' />
                {/*<span className='header-icons__badge'>1</span>*/}
            </li>
            <li className='header-icons__item header-icons__item--purple-light'>
                 <img src={settings} className='header-icons__icon' alt='settings' />
                {/*<span className='header-icons__badge'>1</span>*/}
            </li>
        </ul>
    </header>
);

Header.propTypes = {};

export default Header;
