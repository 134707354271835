import React, {useState} from 'react';
import axios from "../../../utils/interceptors/index";

import {runInAction} from "mobx";
import {observer} from "mobx-react-lite";

import styles from './PopupWallets.module.css'

import UsersWallets from "../../../store/UsersWallets";
import IsShow from "../../../store/IsShow";
import metamaskWallet from '../../../assets/icons/wallets/Metamask-icon.png'
import trustWallet from '../../../assets/icons/wallets/trust-wallet-icon.png'
import mathWallet from '../../../assets/icons/wallets/math-wallet-icon.png'
import tokenPocket from '../../../assets/icons/wallets/token-pocket-icon.png'
import walletConnect from '../../../assets/icons/wallets/wallet-connect-icon.png'
import BinanceWallet from '../../../assets/icons/wallets/binance-wallet-icon1.png'
import infoIcon from '../../../assets/icons/wallets/info.png'
import listOfWallet from "../../../containers/listOfWallet";

const ethereum = window.ethereum;

const PopupWallets = observer( () => {

    const closeHandler = () => {
        IsShow.changeVisionConnectWalletPopup()
    };

    const isSuitableNetwork = (usersChain) => {
        const avalibleNetworks = process.env.REACT_APP_SUPPORTABLE_NETWORK_IDS.split(',')
        return avalibleNetworks.includes(usersChain && usersChain.toString())
    }

    let timeoutId

    const handleTimeout = () => {
        timeoutId = setTimeout(() => {
            IsShow.changeVisionReloadOnDeletePopup()
        }, 60000)
    }

    const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = null;
    }


    const connectMetamaskHandler = () => {

        if (!timeoutId) {
            handleTimeout();
        }

        // post user wallet ID
        if (ethereum) {
            if (isSuitableNetwork(ethereum.networkVersion) || (isSuitableNetwork(ethereum.networkVersion) && !ethereum.selectedAddress)) {
                ethereum.request({method: 'eth_requestAccounts'}).then(() => {
                    resetTimeout();
                    axios.post('/api/v1/wallets/', {
                        "address": ethereum.selectedAddress.toLowerCase(),
                    }, {
                        headers: {
                            isLoader: true
                        },
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                UsersWallets.connectWallet(response.data)
                                IsShow.changeVisionPopupSpinner()
                                IsShow.changeVisionConnectWalletPopup()
                            }
                        })
                        .catch((error) => {
                            if (error.status === 400) {
                                IsShow.changeVisionAlreadyConnectedPopup()
                                runInAction(() => {
                                    IsShow.showPopupSpinner = false
                                    IsShow.isVisible = false
                                })
                            }
                            IsShow.changeVisionConnectWalletPopup()
                        })
                }).catch(e => {
                    resetTimeout();
                    if (e.code === 4001) {
                        IsShow.changeVisionRejectPopup()
                    }
                    if (e.code === -32002) {
                        IsShow.changeVisionPendingPopup()
                    }
                });


            } else {
                IsShow.changeVisionWrongChainPopup()
            }
        } else {
            window.open('https://metamask.io/', '_blanc')
            IsShow.changeVisionConnectWalletPopup()
            if (navigator.vendor.toString().toLowerCase() !== 'Apple Computer, Inc.'.toLowerCase()) {
                IsShow.changeVisionReloadPopup()
            }
        }

        };

    return (
        <>

            <div
                style={{
                    visibility: IsShow.showConnectWalletPopup ? "visible" : "hidden",
                    opacity: IsShow.showConnectWalletPopup ? "1" : "0"
                }}
                className={styles.overlay}
            >
                <div className={styles.popup}>
                    <h2>connect to a wallet</h2>
                    <span className={styles.close} onClick={closeHandler}>&times;</span>
                    <div className={styles.content}>
                        <ul>
                            <li>
                                <button className={`${styles.button} ${styles.active}`} onClick={connectMetamaskHandler}>
                                    Metamask
                                    <div className={styles.iconWrapper}>
                                        <img className={styles.icon} src={metamaskWallet} alt="" />
                                    </div>
                                </button>
                            </li>
                            <li>
                                <button className={`${styles.button} ${styles.inactive}`} disabled={true}>
                                    TrustWallet
                                    <img className={styles.icon} src={trustWallet} alt="" />
                                </button>
                            </li>
                            <li>
                                <button className={`${styles.button} ${styles.inactive}`} disabled={true}>
                                    MathWallet
                                    <img className={styles.icon} src={mathWallet} alt="" />
                                </button>
                            </li>
                            <li>
                                <button className={`${styles.button} ${styles.inactive}`} disabled={true}>
                                    TokenPocket
                                    <div className={styles.iconWrapper}>
                                        <img className={styles.icon} src={tokenPocket} alt="" />
                                    </div>
                                </button>
                            </li>
                            <li>
                                <button className={`${styles.button} ${styles.inactive}`} disabled={true}>
                                    WalletConnect
                                    <img className={styles.icon} src={walletConnect} alt="" />
                                </button>
                            </li>
                            <li>
                                <button className={`${styles.button} ${styles.inactive}`} disabled={true}>
                                    Binance Chain Wallet
                                    <img className={styles.icon} src={BinanceWallet} alt="" />
                                </button>
                            </li>
                        </ul>
                        <div className={styles.info}>
                            <img src={infoIcon} alt="" />
                            Learn how to <a href="https://metamask.io/faqs/" target="_blank">connect</a>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
});

export default PopupWallets;