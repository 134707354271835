import { makeAutoObservable, runInAction, toJS } from "mobx";
import axios from "../utils/interceptors/index";
import IsShow from "./IsShow";

const sortByField = (field) => {
    return (a, b) => a[field] < b[field] ? 1 : -1;
};

class UsersWallets {
    List = []
    isGetData = false

    constructor() {
        makeAutoObservable(this)
    }

    connectWallet(wallet) {
            axios.get('/api/v1/wallets/')
                .then(res => wallet.balance = res.data.results.sort(sortByField('id'))[0].balance)
                .then(() => runInAction(() => {
                    this.isGetData = false
                    this.fetchList()
                }))
    }
    disconnectWallet(id) {
        axios.delete(`/api/v1/wallets/${id}/`)
            .then((response) => {
                if (response.status === 200) {
                    this.fetchList()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    fetchList() {
        axios.get(`/api/v1/wallets/?page_size=100000000`)
            .then((response) => {
                runInAction(() => {
                    this.isGetData = true
                })
                if (response.status === 200) {
                    runInAction(()=> {
                        this.List = response.data.results
                        this.List.sort(sortByField('id'))
                    })
                }
                if (response.data.results.length === 0) {
                    IsShow.changeVisionEmptyPopup()
                }
            })
            .catch((error) => {
                runInAction(() => {
                    this.isGetData = true
                })
                console.log(error);
            }).finally(() => {
                IsShow.setIsLoading(false)
        });

    }

    updateBalance(data) {
        this.List = toJS(this.List).map( wallet => {
          if (wallet.address.toLowerCase() === data.address.toLowerCase()) {
              return { ...wallet, balance: data.balance }
          }
          return wallet
        })
    }
}

export default new UsersWallets()